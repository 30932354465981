import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useTrading } from "features/store";
import { CallsHistoryParams, useGetCallsHistoryQuery } from "features/trading/accounts";
import { OrderType } from "constants/index"
import { formatReportNumber, formatUsDate, mapOptionNameFromParams, parseOptionSymbol } from "helpers";
import CustomDatePicker, { DateObject } from "react-multi-date-picker";


export const CallsHistory = () => {
  const { currentStrategy, orderType } = useTrading();
  const [range, setRange] = useState<DateObject[] | undefined>(undefined);

  const periodDates = useMemo(() => {
    if (!range) {
      return null;
    }

    if (range.length === 2) {
      const [startDate, endDate] = range;
      return [
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      ];
    }

    return null;
  }, [range]);

  useEffect(() => {
    setRange(undefined);
  }, [currentStrategy])

  const params = useMemo<CallsHistoryParams | null>(() => {
    if (!currentStrategy || !periodDates) {
      return null;
    }

    const [start_date, end_date] = periodDates;
    return {
      strategy_id: currentStrategy.id,
      start_date,
      end_date,
    };
  }, [currentStrategy, periodDates]);

  const { currentData, isLoading, isFetching } = useGetCallsHistoryQuery(
    { ...params! },
    { skip: !params }
  );

  const columns: GridColDef[] = [
    {
      headerName: "Call Name",
      field: "symbol",
      width: 220,
      valueFormatter: ({ value }) => {
        const symbolParams = parseOptionSymbol(value);
        return mapOptionNameFromParams(symbolParams);
      },
    },
    {
      headerName: "Order Date",
      field: "order_date",
      width: 120,
      valueFormatter: ({ value }) => formatUsDate(value),
    },
    {
      headerName: "Side",
      field: "side",
      width: 50,
    },
    {
      headerName: "Change",
      field: "change",
      renderCell: ({ value }: GridRenderCellParams) => (
        <Box
          component="span"
          style={{ color: value >= 0 ? "green" : "red" }}
        >
          {formatReportNumber(value)}
        </Box>
      ),
    },
  ];

  const totalCashFlow = useMemo<number>(() => {
    if (!currentData) {
      return 0;
    }

    return currentData.reduce((acc, cur) => acc + cur.change, 0)
  }, [currentData]);

  return (
    <Grid container flexDirection="row" sx={{ paddingTop: 1 }}>
      <Grid xs={4} >
        <CustomDatePicker
          range
          format="MM/DD/YYYY"
          onChange={(dates: DateObject[]) => setRange(dates)}
          dateSeparator=" - "
          value={range}
          style={{ height: "40px", width: "200px", fontSize: "16px" }}
          placeholder="Pick range"
          showOtherDays
        />
        <Typography sx={{ paddingTop: 1 }}>
          Total Cash Flow: <b>{formatReportNumber(totalCashFlow)}</b>
        </Typography>
      </Grid>
      <Grid xs={8}>
        <DataGrid
          getRowId={(row) => `${row.side}_${row.symbol}_${row.order_date}`}
          rows={currentData || []}
          rowCount={currentData?.length}
          columns={columns}
          loading={isLoading || isFetching}
          density="compact"
          hideFooter
          sx={{ height: orderType === OrderType.MARKET ? "250px" : "300px "}}
        />
      </Grid>
    </Grid>
  )
};
