import { Box, Divider, Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { CompositeReportPreview } from "features/trading/accounts"
import { formatPercent, mapOptionNameFromParams, formatUsDate, parseOptionSymbol, formatReportNumber } from "helpers/index";

interface Props {
  data: CompositeReportPreview;
}

export const AccountPreview = ({ data }: Props) => {
  const {
    account_number,
    start_date,
    end_date,
    start_value,
    end_value,
    perfomance,
    shares_start_value,
    shares_end_value,
    start_adjustment,
    end_adjustment,
    cash_flow,
    shares_data,
    transactions_info,
    start_open_positions,
    end_open_positions,
    dividend_income,
    dividend_transactions,
  } = data;

  const openPosColumns: GridColDef[] = [
    {
      field: "symbol",
      headerName: "Name",
      width: 250,
      valueFormatter: ({ value }) => {
        const symbolParams = parseOptionSymbol(value);
        return mapOptionNameFromParams(symbolParams);
      },
    },
    {
      field: "created_on_date",
      headerName: "Open Date",
      width: 120,
      valueFormatter: ({ value }) => formatUsDate(value),
    },
    {
      field: "qty",
      headerName: "Qty"
    },
    {
      field: "price",
      headerName: "Price",
    },
    {
      field: "close_price",
      headerName: "Close Price",
      valueFormatter: ({ value }) => formatReportNumber(value),
    },
  ];

  const orderColumns: GridColDef[] = [
    {
      field: "symbol",
      headerName: "Name",
      width: 250,
      valueFormatter: ({ value }) => {
        const symbolParams = parseOptionSymbol(value);
        return mapOptionNameFromParams(symbolParams);
      },
    },
    {
      field: "created_on_date",
      headerName: "Order Date",
      width: 120,
      valueFormatter: ({ value }) => formatUsDate(value),
    },
    {
      field: "side",
      headerName: "Side",
    },
    {
      field: "qty",
      headerName: "Qty"
    },
    {
      field: "price",
      headerName: "Price",
    },
    {
      field: "change",
      headerName: "Change",
      align: "right",
      renderCell: ({ value }: GridRenderCellParams) => (
        <Box
          component="span"
          style={{ color: value >= 0 ? "green" : "red" }}
        >
          {formatReportNumber(value)}
        </Box>
      ),
    },
  ];

  const dividendTransColumns: GridColDef[] = [
    {
      field: "ticker",
      headerName: "Ticker",
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      width: 150,
      valueFormatter: ({ value }) => formatUsDate(value),
    },
    {
      field: "transaction_type",
      headerName: "Type",
      width: 250,
    },
    {
      field: "unit_balance",
      headerName: "Shares Qty",
    },
    {
      field: "transaction_amount",
      headerName: "amount",
      valueFormatter: ({ value }) => formatReportNumber(value),
    },
  ];

  return (
    <Grid container>
      <Grid item>
        <Typography>
          Account number: <b>{account_number}</b>
        </Typography>
        <Typography>
          Start Date: <b>{start_date}</b>
        </Typography>
        <Typography>
          End Date: <b>{end_date}</b>
        </Typography>
        <Typography>
          Start Value: <b>{formatReportNumber(start_value)}</b>
        </Typography>
        <Typography>
          End Value: <b>{formatReportNumber(end_value)}</b>
        </Typography>
        <Typography>
          Dividend Income: <b>{formatReportNumber(dividend_income)}</b>
        </Typography>
        <Typography>
          Perfomance: <b>{formatPercent(perfomance)}</b>
        </Typography>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Values details</Typography>
        <Typography>
          Start shares value: <b>{formatReportNumber(shares_start_value)}</b>
        </Typography>
        <Typography>
          Start adjustment: <b>{formatReportNumber(start_adjustment)}</b>
        </Typography>
        <Typography>
          End shares value: <b>{formatReportNumber(shares_end_value)}</b>
        </Typography>
        <Typography>
          End adjustment: <b>{formatReportNumber(end_adjustment)}</b>
        </Typography>
        <Typography>
          Cash flow: <b>{formatReportNumber(cash_flow)}</b>
        </Typography>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Shares info</Typography>
        <Grid container>
        {shares_data.map(item => {
          const { symbol, data: { start, end } } = item;
          const startDate = formatUsDate(start.date);
          const endDate = formatUsDate(end.date);
          const startPrice = formatReportNumber(start.price);
          const endPrice = formatReportNumber(end.price);
          return (
            <Grid item sx={{ marginY: 1, width: '100%' }}>
              <Typography fontWeight="bold">{symbol} {start.overriden || end.overriden && "(amount overriden)"}</Typography>
              <Typography sx={{ paddingLeft: 1}}>Date: <b>{startDate}</b>, Amount: <b>{start.amount}</b>, Price: <b>{startPrice}</b></Typography>
              <Typography sx={{ paddingLeft: 1}}>Date: <b>{endDate}</b>, Amount: <b>{end.amount}</b>, Price: <b>{endPrice}</b></Typography>
            </Grid>
          );
        })}
        </Grid>
        <Divider sx={{ marginY: 1}}/>
        <Typography fontSize="large">Start Open Positions</Typography>
        <Grid>
          <DataGrid
            rows={start_open_positions}
            rowCount={start_open_positions.length}
            columns={openPosColumns}
            density="compact"
            hideFooter
          />
        </Grid>
        <Typography fontSize="large">End Open Positions</Typography>
        <Grid>
          <DataGrid
            rows={end_open_positions}
            rowCount={end_open_positions.length}
            columns={openPosColumns}
            density="compact"
            hideFooter
          />
        </Grid>
        <Typography fontSize="large">Orders info</Typography>
        <Grid>
          <DataGrid
            rows={transactions_info}
            rowCount={transactions_info.length}
            columns={orderColumns}
            density="compact"
            hideFooter
          />
        </Grid>
        <Typography fontSize="large">Dividend transactions</Typography>
        <Grid>
          <DataGrid
            rows={dividend_transactions}
            rowCount={dividend_transactions.length}
            columns={dividendTransColumns}
            density="compact"
            hideFooter
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
