import { createSlice } from "@reduxjs/toolkit";
import { ReportingMode } from "constants/index";

export interface CompPreviewParams {
  composite_id: number | null,
  account_id: number | null;
  start_date: string | null;
  end_date: string | null;
  excluded_accounts: number[];
}

export const DEFAULT_COMP_PREVIEW_PARAMS = {
  composite_id: null,
  account_id: null,
  start_date: null,
  end_date: null,
  excluded_accounts: [],
};

export interface ReportingState {
  compPreviewParams: CompPreviewParams;
  reportingMode: ReportingMode | null;
}

const initialState: ReportingState = {
  compPreviewParams: DEFAULT_COMP_PREVIEW_PARAMS,
  reportingMode: null
};

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setCompPreviewParams: (state, action) => {
      state.compPreviewParams = action.payload;
    },
    setReportingMode: (state, action) => {
      state.reportingMode = action.payload;
    }
  },
});

export default reportingSlice.reducer;
export const {
  setCompPreviewParams,
  setReportingMode,
} = reportingSlice.actions;
