import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAppDispatch, useTrading } from "features/store";
import {
  Strategy,
  useCloseCallMutation,
  useDeleteStagedMlegOrdersMutation,
  useDeleteStagedOrdersMutation,
  useNewCallMutation,
  useSendStagedMlegOrdersMutation,
  useSendStagedOrdersMutation,
  useUpdateStrategyMutation,
} from "features/trading/accounts";
import {
  TradeMode,
  setCurrentStrategy,
  setPositionsPollingInterval,
  setTradeMode,
} from "features/trading/tradingSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TradeConfirmationModal } from "./TradeConfirmationModal";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import CloseIcon from "@mui/icons-material/Cancel";
import { mapOptionName, mapOptionNameFromParams, mapOptionSymbol, parseOptionSymbol } from "helpers";
import ViewListIcon from "@mui/icons-material/ViewList";
import { OptionChainModal } from "./OptionChainModal";
import { DEFAULT_POLLING_INTERVAL, NOTISTACK_FAILURE_OPTIONS, NOTISTACK_SUCCESS_OPTIONS } from "constants/index";
import { OrderTypeSetup } from "./OrderTypeSetup";
import { useSnackbar } from "notistack";
import { CallsHistory } from "./CallsHistory";

export const StrategyTradeSetup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentStrategy, tradeMode, draftOrders } = useTrading();
  const dispatch = useAppDispatch();
  const [sendStagedOrders, { isLoading: isSendInProgress }] =
    useSendStagedOrdersMutation();
  const [sendStagedMlegOrders, { isLoading: isMlegSendInProgress }] =
    useSendStagedMlegOrdersMutation();
  const [deleteStagedOrders, { isLoading: isDeleteInProgress }] =
    useDeleteStagedOrdersMutation();
  const [deleteStagedMlegOrders, { isLoading: isMlegDeleteInProgress }] =
    useDeleteStagedMlegOrdersMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTradeMode(parseInt((event.target as HTMLInputElement).value)));
  };

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const openModal = () => setIsModalOpened(true);
  const closeModal = () => setIsModalOpened(false);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [newCallExpDate, setNewCallExpDate] = useState<Dayjs | null>(dayjs());
  const [newCallStrikePrice, setNewCallStrikePrice] = useState<number>(0);
  const [newCall, newCallRes] = useNewCallMutation();
  const [closeCall, closeCallRes] = useCloseCallMutation();

  const [hedgeRatio, setHedgeRatio] = useState<string | undefined>();
  const [updateStrategy, { isLoading: isUpdatingStrategy }] = useUpdateStrategyMutation();
  useEffect(() => {
    if (currentStrategy) {
      setHedgeRatio(currentStrategy.hedge_ratio)
    }
  }, [currentStrategy])

  const updateHedgeRatio = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const updatedStrategy = { ...currentStrategy };
    updatedStrategy.hedge_ratio = e.currentTarget.value;
    updateStrategy(updatedStrategy as Strategy);
  }, [currentStrategy, updateStrategy]);

  useEffect(() => {
    const { data, isSuccess } = newCallRes;
    if (isSuccess) {
      dispatch(setCurrentStrategy(data));
    }
  }, [newCallRes, dispatch, setCurrentStrategy]);

  useEffect(() => {
    const { data, isSuccess } = closeCallRes;
    if (isSuccess) {
      dispatch(setCurrentStrategy(data));
    }
  }, [closeCallRes, dispatch, setCurrentStrategy]);

  const optionSymbol = useMemo(() => {
    if (currentStrategy && newCallExpDate && newCallStrikePrice) {
      return mapOptionSymbol(
        currentStrategy.name,
        newCallExpDate,
        newCallStrikePrice,
      );
    }

    return "N/A";
  }, [currentStrategy, newCallExpDate, newCallStrikePrice, mapOptionSymbol]);

  const optionName = useMemo(() => {
    if (currentStrategy && newCallExpDate && newCallStrikePrice) {
      return mapOptionName(
        currentStrategy.name,
        newCallExpDate,
        String(newCallStrikePrice),
      );
    }

    return "N/A";
  }, [currentStrategy, newCallExpDate, newCallStrikePrice, mapOptionName]);

  const changeCurrentCall = useCallback(() => {
    const asOfDate = dayjs().format("YYYY-MM-DD");
    newCall({
      id: currentStrategy?.id,
      as_of_date: asOfDate,
      name: optionName,
      symbol: optionSymbol,
    });
    setIsEditMode(false);
  }, [
    dayjs,
    currentStrategy,
    optionName,
    optionSymbol,
    newCall,
    setIsEditMode,
  ]);

  const closeCurrentCall = useCallback(() => {
    if (currentStrategy) {
      closeCall({ id: currentStrategy.id });
    }
  }, [currentStrategy, closeCall]);

  useEffect(() => {
    setIsEditMode(false);
  }, [currentStrategy]);

  useEffect(() => {
    if (!isEditMode) {
      setNewCallExpDate(dayjs());
      setNewCallStrikePrice(0);
    }
  }, [isEditMode]);

  const renderEditControls = useCallback(() => {
    if (isEditMode) {
      return (
        <>
          <Tooltip title="Cancel new call">
            <IconButton onClick={() => setIsEditMode(false)}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Confirm new call">
            <IconButton
              onClick={changeCurrentCall}
              disabled={optionSymbol === "N/A"}
            >
              <ConfirmIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    }

    return (
      <Tooltip title="Set new call manually">
        <IconButton
          onClick={() => setIsEditMode(true)}
          disabled={!currentStrategy}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }, [
    isEditMode,
    optionSymbol,
    currentStrategy,
    setIsEditMode,
    changeCurrentCall,
  ]);

  const renderEditModeFields = useCallback(() => {
    if (isEditMode) {
      return (
        <>
          <Grid container paddingY={1} alignItems="center">
            <Grid item xs={2}>
              <InputLabel>Expiration Date:</InputLabel>
            </Grid>
            <Grid item xs={10}>
              <DatePicker
                value={newCallExpDate}
                onChange={(date) => setNewCallExpDate(date)}
                shouldDisableDate={(date) =>
                  dayjs().startOf("D") > date.startOf("D")
                }
                sx={{ width: "30ch" }}
              />
            </Grid>
          </Grid>
          <Grid container paddingY={1} alignItems="center">
            <Grid item xs={2}>
              <InputLabel>Strike Price:</InputLabel>
            </Grid>
            <Grid item xs={10}>
              <TextField
                type="number"
                size="small"
                value={newCallStrikePrice}
                onChange={(e) =>
                  setNewCallStrikePrice(Number(e.target.value || 0))
                }
                sx={{ width: "30ch" }}
              />
            </Grid>
          </Grid>
        </>
      );
    }

    return null;
  }, [
    isEditMode,
    newCallExpDate,
    setNewCallExpDate,
    newCallStrikePrice,
    setNewCallStrikePrice,
    dayjs,
  ]);

  const [isOCModalOpened, setIsOCModalOpened] = useState<boolean>(false);
  const openOCModal = () => setIsOCModalOpened(true);
  const closeOCModal = () => setIsOCModalOpened(false);

  useEffect(() => {
    dispatch(setPositionsPollingInterval(
      isOCModalOpened ? 0 : DEFAULT_POLLING_INTERVAL
    ));
  }, [dispatch, setPositionsPollingInterval, isOCModalOpened]);

  const onSendStagegOrders = useCallback(() => {
    const mutationFunc = tradeMode === TradeMode.MultiLeg
      ? sendStagedMlegOrders
      : sendStagedOrders;

    const assetId = tradeMode === TradeMode.MultiLeg
      ? currentStrategy?.asset
      : currentStrategy?.current_call;

    mutationFunc({
      asset_id: assetId || -1,
    }).then((res) => {
      if ("error" in res) {
        enqueueSnackbar("Staged orders send has failed.", NOTISTACK_FAILURE_OPTIONS as any);
      } else {
        enqueueSnackbar("Staged orders were sent!", NOTISTACK_SUCCESS_OPTIONS as any)
      }
    });
  }, [
    tradeMode,
    sendStagedOrders,
    sendStagedMlegOrders,
    currentStrategy,
    enqueueSnackbar,
    NOTISTACK_SUCCESS_OPTIONS,
    NOTISTACK_FAILURE_OPTIONS
  ]);

  const onDeleteStagedOrders = useCallback(() => {
    const mutationFunc = tradeMode === TradeMode.MultiLeg
      ? deleteStagedMlegOrders
      : deleteStagedOrders;

    const assetId = tradeMode === TradeMode.MultiLeg
      ? currentStrategy?.asset
      : currentStrategy?.current_call;

    mutationFunc({
      asset_id: assetId || -1,
    }).then((res) => {
      if ("error" in res) {
        enqueueSnackbar("Staged orders delete has failed.", NOTISTACK_FAILURE_OPTIONS as any);
      } else {
        enqueueSnackbar("Staged orders were deleted!", NOTISTACK_SUCCESS_OPTIONS as any);
      }
    });
  }, [
    tradeMode,
    deleteStagedOrders,
    deleteStagedMlegOrders,
    currentStrategy,
    enqueueSnackbar,
    NOTISTACK_SUCCESS_OPTIONS,
    NOTISTACK_FAILURE_OPTIONS
  ]);

  const radioButtonStyles = { borderRadius: 10, paddingRight: 1, margin: 1, marginLeft: 0 };

  const currentCallName = useMemo(() => {
    if (isEditMode) {
      return optionName;
    }

    if (!currentStrategy) {
      return "-";
    }

    const { current_call, current_call__symbol } = currentStrategy;

    if (current_call && current_call__symbol) {
      const optionSymbolParams = parseOptionSymbol(current_call__symbol);
      if (optionSymbolParams.isCorrect) {
        return mapOptionNameFromParams(optionSymbolParams);
      }

      return `Incorrect Option Symbol (${current_call__symbol})`
    }

    return "Open";
  }, [currentStrategy, isEditMode, optionName]);

  const previousCallName = useMemo(() => {
    if (!currentStrategy) {
      return "-";
    }

    const { previous_call, previous_call__symbol } = currentStrategy;

    if (previous_call && previous_call__symbol) {
      const optionSymbolParams = parseOptionSymbol(previous_call__symbol);
      if (optionSymbolParams.isCorrect) {
        return mapOptionNameFromParams(optionSymbolParams);
      }

      return `Incorrect Option Symbol (${previous_call__symbol})`
    }

    return "Open";
  }, [currentStrategy, isEditMode]);

  return (
    <>
      <Card>
        <Grid container>
          <Grid xs={6}>
            <Box maxWidth={800}>
              <Typography variant="h6">Trade Setup</Typography>
              <Grid container>
                <Grid container paddingY={1} alignItems="center">
                  <Grid item xs={2}>
                    <InputLabel>Current Call:</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      value={currentCallName}
                      sx={{ width: "30ch" }}
                    />
                    {renderEditControls()}
                    {!isEditMode && (
                      <Tooltip title="Choose current call from Option Chain">
                        <IconButton
                          onClick={openOCModal}
                          disabled={!currentStrategy}
                        >
                          <ViewListIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!isEditMode && (
                      <Tooltip title="Close Current Call">
                        <IconButton
                          onClick={closeCurrentCall}
                          disabled={!currentStrategy?.current_call}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isEditMode && (
                      <span style={{ whiteSpace: "pre" }}>{optionSymbol}</span>
                    )}
                  </Grid>
                </Grid>
                {renderEditModeFields()}
                <Grid container paddingY={1} alignItems="center">
                  <Grid item xs={2}>
                    <InputLabel>Previous Call:</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      value={previousCallName}
                      sx={{ width: "30ch" }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container paddingY={1} alignItems="center">
                  <Grid item xs={2}>
                    <InputLabel>Hedge Ratio:</InputLabel>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      size="small"
                      value={hedgeRatio}
                      onChange={(e) => setHedgeRatio(e.currentTarget.value)}
                      inputProps={{
                        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === 'Enter') {
                            updateHedgeRatio(e);
                          }
                        },
                        onBlur: () => setHedgeRatio(currentStrategy?.hedge_ratio),
                      }}
                      disabled={isUpdatingStrategy}
                      sx={{ width: "30ch" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <OrderTypeSetup />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup row value={tradeMode} onChange={handleChange}>
                    <FormControlLabel
                      value={TradeMode.CallSellShort}
                      control={<Radio />}
                      label="Sell Short (Open)"
                      sx={{ ...radioButtonStyles, background: "rgba(255, 0, 0, 0.25)" }}
                    />
                    <FormControlLabel
                      value={TradeMode.CallBuyToCover}
                      control={<Radio />}
                      label="Buy to Cover (Close)"
                      sx={{ ...radioButtonStyles, background: "rgba(0, 255, 0, 0.25)" }}
                    />
                    <FormControlLabel
                      value={TradeMode.MultiLeg}
                      control={<Radio />}
                      label="MLO"
                      sx={{ ...radioButtonStyles, background: "rgba(0, 0, 255, 0.25)" }}
                      disabled={!currentStrategy}
                    />
                    <FormControlLabel
                      value={TradeMode.CallRebalance}
                      control={<Radio />}
                      label="Rebalance"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    onClick={openModal}
                    disabled={!draftOrders || draftOrders.length === 0 || isEditMode}
                  >
                    Trade
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginX: 1 }}
                    onClick={onSendStagegOrders}
                    disabled={!currentStrategy || isSendInProgress || isMlegSendInProgress}
                  >
                    Send Staged Orders
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginX: 1 }}
                    onClick={onDeleteStagedOrders}
                    disabled={!currentStrategy || isDeleteInProgress || isMlegDeleteInProgress}
                  >
                    Delete Staged Orders
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Box maxWidth={800}>
              <Typography variant="h6">History of trades</Typography>
              <CallsHistory />
            </Box>
          </Grid>
        </Grid>
      </Card>
      <TradeConfirmationModal isOpened={isModalOpened} onClose={closeModal} />
      {isOCModalOpened && <OptionChainModal isOpened={isOCModalOpened} onClose={closeOCModal} />}
    </>
  );
};
